.doctor-box-span{
    display: flex;
    flex:1;
    text-align:center;
    margin:1%;
}

.doctor-box-span-text{
    color:white;
    font-family: "monospace";
}

.doctor-box-div{
    box-shadow: rgba(0, 0, 0, 1) 2px 5px 15px;
    display: flex;
    width:22%;
    background-color: #0885bf;
    flex-direction: column;
    margin: 1%;
    padding: 1%;
    border-radius: 8%;
    flex-wrap: wrap;
}

/**responsive css kodlari*/

@media only screen and (max-width: 1600px) {
    .doctor-box-div{
        width: 30%;
    }
}

@media only screen and (max-width: 992px) {
    .doctor-box-div{
        width: 40%;
    }
}

@media only screen and (max-width: 768px) {
    .doctor-box-div{
        width: 100%;
    }
}
