.dialog-div{
    width: 50vw;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1600px) {
    .dialog-div{
        width: 60vw;
    }
}

@media only screen and (max-width: 992px) {
    .dialog-div{
        width: 80vw;
    }
}

@media only screen and (max-width: 768px) {
    .dialog-div{
        width: 100%;
    }
}
