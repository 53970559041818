.Signup-Form {
    width: 460px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: #ffff;
    border-radius: 3rem;
}

.SignupForm-content {
    padding-left: 12%;
    padding-right: 12%;
}

.info-p{
    --tw-text-opacity: 1;
    color: rgb(93 93 93/var(--tw-text-opacity));
    font-size: .80rem;
    line-height: 1rem;
}

.aggrement-a{
    text-decoration-line: underline;
    --tw-text-opacity: 1;
    color: rgb(24 78 119/var(--tw-text-opacity));
    font-size: .75rem;
    line-height: 1rem;
}

.SignupForm-title {
    text-align: left;
    margin-bottom: 1rem;
    font-size: 19px;
    font-family: "monospace";
    color: #1a98d5;
    font-weight: 800;
    margin: 1px;
    padding: 1px;
}

.SignupForm-control {
    width: 320px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0.5rem;
    background-color: white;
    margin: 0.5rem;
    border: none;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.4);
    outline-width: 1px;
}

.SignupForm-control::placeholder {
    padding: 15px;
}

.Signup-btn {
    border-radius: 1rem;
    width: 136px;
    height: 34px;
    background-color: #1a98d5;
    color: #ffff;
    font-weight: 600;
    font-family: "monospace";
    font-size: 17px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-forgot-password {
    position: relative;
    text-align: center;
    font-family: "monospace";
    text-decoration: none;
}

.signup-forgot-password span {
    text-decoration: none;
    color: #1295d6;
}

.signup-button-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control-not-block{
    display: flex !important;
}
