.contactsec {
  font-family: "monospace";
  color: #0785c7;
  display: flex;
  padding-top: 75px;
  justify-content: center;
  align-items: flex-start;
}

.contactimg {
  width: 25%;
  padding-top: 8%;
  margin: 5%;
}

.contactborderimg {
  margin: 0 auto;
  margin-bottom: 25px;
}

.contactformbase {
  width: 30%;
  height: 25%;
  padding: 30px 15px 30px 15px;
  margin-bottom: 125px;
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contactformh3 {
  margin-top: 0;
  font-weight: 900;
}

.contactform {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contactforminput {
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  margin: 0 0 10px 0;
  padding: 0.4em;
  text-align: center;
}

.div_contactform_namemail {
  width: 100%;
}
.contactformemailinput {
  width: 100%;
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  margin: 0 0 10px 0;
  padding: 0.4em;
}

.contactformmessageinput {
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 2em;
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  margin: 0 0 10px 0;
  padding: 7em;
  text-align: center;
}

.contactformbutton {
  font-family: "monospace";
  font-weight: bold;
  padding: 4px 8% 4px 8%;
  width: fit-content;
  margin: auto;
  border: 3px solid #0785c7;
  border-radius: 0.8em;
  color: #0785c7;
  background-color: white;
  font-weight: bold;
}

@media (min-width: 2000px) {
  body {
    font-size: 120%;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  body {
    font-size: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  body {
    font-size: 90%;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  body {
    font-size: 80%;
  }
}

@media (max-width: 800px) {
  body {
    font-size: 75%;
  }
}
@media only screen and (max-width: 924px) {
  .contactimg {
    display: none;
  }
  .contactformbase {
    width: 80%;
  }
}
