.main-footer {
  background-image: url(/assets/img/footerbackground.png);
}

.footer-widget ul {
  padding-left : 0 !important;
}

.text {
  font-family: "monospace";
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.7em;
  margin-top: 15px;
}

.footer-list {
  padding: 0;
}

.footer-list li a {
  font-family: "monospace";
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.7em;
  font-weight: 500;
  text-decoration: none;
}
.footer-list li {
  list-style: none;
}

.main-footer h2 {
  font-family: "monospace";
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.main-footer .logo img {
  width: 130px;
  height: auto;
}
.list-style-two {

}

.list-style-two li .icon {
  top: 0;
  color: #ffffff;
  font-size: 18px;
  line-height: 0.7em;
}

.list-style-two li a {
  font-family: "monospace";
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
}
.list-style-two li {
  list-style: none;
}

.footer-widget .social-box li {
  line-height: 1em;
  padding-left: 0;
  color: #fff;
  font-size: 30px;
  padding-right: 12px;
  display: inline-block;
}

.footer-widget .social-box {
  float: left;
}

.social-box li a {
  text-decoration: none;
  color: #ffffff;
}

.social-box li .icon {
  font-size: 40px;
  position: absolute;
  color: #ffffff;
  left: 0px;
  top: 0;
}

.app-box li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.app-box img {
  max-width: 100%;
  height: auto;
}

.footerContainer{
  position: relative;
  top: 200px
}

.mt-200custom{
  margin-top:200px;
}

@media only screen and (max-width: 767px) {
  .main-footer{
    margin-top: 10px
  }

  .first-container {
    margin-top: 300px !important;
  }

  .footer-widget {
    margin-top: 0px !important;
  }

  .main-footer {
    background-color: #0585BF !important;
    padding-bottom: 35px !important;
  }

  .footerContainer{
    top: 10px !important;
  }

  .mt-200custom{
    margin-top:10px !important;
  }

}
