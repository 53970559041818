.media-container{
    display: flex;
    flex-direction: column;
    min-height: 35vw;
    background-color: #0885bf;
    margin-top: 10em;
}

.media-title-area{
    display: flex;
    height: 10%;
    justify-content: center;
    position: relative;
    border-bottom : 1px solid white;
}

.media-slider-area{
    display: flex;
    height: 90%;
    padding: 5vw;
}

.media-slider-area .items {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex:1;
    margin-left: 15%;
    margin-right: 15%;
    animation: carouselAnim 4s infinite alternate linear;
}

.media-slider-area .items .entry {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 23%;
}

.media-slider-area .items .entry img {
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

@keyframes carouselAnim {
    from {
        transform: translate(-10%, 0);
    }
    to {
        transform: translate(calc(10%));
    }
}

.media-container .faders {
    width: 100%;
}

.media-title-area .media-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-family: "Cocogoose Pro Block";
    font-size: 10vw;
    opacity: 0.3;;
}

.media-title-area .media-title2 {
    display: flex;
    font-family: "monospace";
    font-size: 5vw;
    color: #fff;
    font-weight: 900;
    position: absolute;
    left: 45%;
    top: 20%;
}

.media-title-area .bgheart{
    display: flex;
}

.media-title-area .bgheart img {
    position: absolute;
    top: -45%;
}

@media only screen and (max-width: 768px) {
    .media-container{
        margin-top: 10vw;
    }

    .media-slider-area .items .entry {
        width: 24%;
    }

    .media-slider-area{
        padding: 5vw;
    }
}
