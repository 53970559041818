.aboutpage {
  margin: 0;
  padding-bottom: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
}

.aboutsec1 {
  margin: 100px 0 0 0;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.aboutsec1info {
  width: 50%;

}

.aboutsec1info p {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

.about-title-1 {
  font-family: "Cocogoose Pro Block";
 text-align: left;
  color: #0785c7;
}

.about-title-2 {
  font-family: "Cocogoose Pro Block";
  color: #0785c7;
  font-size: 48px;
}

.about-title-3 {
  font-family: "Cocogoose Pro Block";
  color: #fff;
  font-size: 36px;
}

.aboutsec2li {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

.about-title-4 {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

.about-title-5 {
  font-family: "Cocogoose Pro Block";
  color: #0785c7;
}
.about-title-6 {
  font-family: "Cocogoose Pro Block";
  color: #0785c7;
}

#aboutsec1img {
  width: 25%;
  margin-left: 5%;
}

.aboutsec2 {
  margin: 0 0 50px 0;
  padding: 100px;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/img/aboutsecbackground.png);
  background-size: cover;
  background-position-y: top;
}

.aboutsec2li {
  text-align: justify;
  margin-bottom: 10px;
}

#aboutsec2img {

  width: 25%;
}

#aboutsec2info {
   width: 75%;
  color: white;
}

.aboutsec3 {
  margin: 0;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#aboutsec3info {
  width: 50%;
  margin-right: 5%;
}

#aboutsec3info p {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

#aboutsec3img {
  width: 50%;
}

.aboutsec4 {
  margin: 0;
  text-align: justify;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

#aboutsec4info {
  width: 50%;
  margin-left: 5%;
  padding-bottom: 180px;
}

#aboutsec4info p {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
  left: 30px;
}

#aboutsec4img {
  width: 400px;
}



@media (min-width: 2000px) {
  body {
    font-size: 120%;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  body {
    font-size: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  body {
    font-size: 90%;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  body {
    font-size: 80%;
  }



}
@media (min-width: 300px) and (max-width:800px){
  #aboutsec3img {
    width: 150px;
  }
  #aboutsec4img {
    width: 150px;
  }

}

@media (max-width: 800px) {
  body {
    font-size: 75%;
  }
  #aboutsec2img {

    display: none;
  }
  #aboutsec2info {
    margin-top: 50px;
    width: 100%;
   color: white;
 }
}
@media (min-width: 300px) and (max-width:800px){
  .about-title-2 {

    font-size: 25px;
    text-align: left;
  }
}
