.content-section {
  padding: 8px;
}

.doctor-price-label {
  font-size: 2em;
  font-weight: 700;
  display: none;
}

.doctor-price-label-not-login {
  display: none;
}

.price-wrapper:hover .doctor-price-label-not-login {
  display: flex;
}

.price-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price-wrapper {
  border: 1px solid #ced4da;
  border-radius: 6px;
  height: 48%;
}

.price-wrapper:hover .doctor-price-label {
  display: flex;
}

.price-wrapper:hover {
  border: 1px solid #0785c7;
}

.doctor-calendar {
  width: 100%;
  height: 100%;
}

.doctor-calendar-available-day {
  color: #0785c7;
  font-size: large;
}

.patient-review {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 12px;
  margin-bottom: 8px;
}

.doctor-gallery-media-thumbnail {
  width: 90%;
  aspect-ratio: 1;
  border: 1px solid #ced4da;
}

.doctor-gallery-media {
  width: 100%;
  max-height: 400px;
  border: 1px solid #ced4da;
  object-fit : contain;
}

.doctor-video {
  width: 100%;
  aspect-ratio: 1.5;
}

.doctor-calendar .p-datepicker table td {
  padding: 0;
}

.doctor-calendar .p-datepicker .p-datepicker-header {
  padding: 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #0785c7 !important;
  color: #0785c7 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset 0 0 0 0.1rem #7ab0ce !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #0785c7 !important;
}


@media only screen and (max-width: 768px) {

  .price-wrapper:hover .doctor-price-label {
    display: flex;
    font-size: 1.5em;
    padding-bottom: 3.5rem;
  }

  .price-wrapper{
    margin-top: 1% !important;
  }
}
