/* #root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-right: 0.4rem;
} */

.row {
  --bs-gutter-y: 0 !important;
  --bs-gutter-x: 0 !important;
}

.d-btn {
  text-transform: uppercase;
  border-radius: 24px !important;
  border-width: 2px !important;
  font-weight: 800 !important;
  margin-top: 1%;
  /* color: #58595b !important; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  border-right: 1px solid #0785c7;
}

.profile-photo {
  border-radius: 100%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  border: 1px solid #0785c7;
  width: 100px !important;
}

.profile-photo-sidebar {
  border-radius: 100%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  min-width: 180px !important;
  max-width: 200px !important;
  border: 1px solid #0785c7;
}

.profile-photo-inline {
  width: 100%;
  min-width: 180px !important;
  max-width: 200px !important;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {

  .sidebar{
    justify-content: center;
  }

  .mjustify-center{
    justify-content: center;
  }

  .mtext-center{
    text-align: center;
  }

}

.content-section {
  padding: 8px;
}

.doctor-price-label {
  font-size: 2em;
  font-weight: 700;
  display: none;
}

.doctor-price-label-not-login {
  display: none;
}

.price-wrapper:hover .doctor-price-label-not-login {
  display: flex;
}

.price-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price-wrapper {
  border: 1px solid #ced4da;
  border-radius: 6px;
  height: 48%;
}

.price-wrapper:hover .doctor-price-label {
  display: flex;
}

.price-wrapper:hover {
  border: 1px solid #0785c7;
}

.doctor-calendar {
  width: 100%;
  height: 100%;
}

.doctor-calendar-available-day {
  color: #0785c7;
  font-size: large;
}

.patient-review {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 12px;
  margin-bottom: 8px;
}

.doctor-gallery-media-thumbnail {
  width: 90%;
  aspect-ratio: 1;
  border: 1px solid #ced4da;
}

.doctor-gallery-media {
  width: 100%;
  max-height: 400px;
  border: 1px solid #ced4da;
  object-fit : contain;
}

.doctor-video {
  width: 100%;
  aspect-ratio: 1.5;
}

.doctor-calendar .p-datepicker table td {
  padding: 0;
}

.doctor-calendar .p-datepicker .p-datepicker-header {
  padding: 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #0785c7 !important;
  color: #0785c7 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset 0 0 0 0.1rem #7ab0ce !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #0785c7 !important;
}


@media only screen and (max-width: 768px) {

  .price-wrapper:hover .doctor-price-label {
    display: flex;
    font-size: 1.5em;
    padding-bottom: 3.5rem;
  }

  .price-wrapper{
    margin-top: 1% !important;
  }
}

.appointment-container{
    display: flex;
    flex-direction: column;
}

.appointment-title{
    height: 10%;
    display: flex;
    justify-content: center;
}

.appointment-title h3{
    font-weight: bold;
    color: #0885bf;
    font-family: "monospace";
}

.appointment-step{
    height: 80%;
}

.angles-area{
    height: 10%;
    display: flex;
    justify-content: space-between;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #0885bf !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #0885bf !important;
}

.flex-div{
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    padding: 2%;
    flex-wrap: wrap;
}

.options {
    list-style: none;
}

.option {
    display: block;
    width: 100%;
    padding: 16px;
    border: none;
    background: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    text-align: left;
    cursor: pointer;
    transition: 0.3s background;
    outline: none;
}

.option.is-selected {
    background: rgb(103, 121, 225);
    color: #fff;
}

/**
 * Main wrapper
 */
.select-search {
    width: 300px;
    height:50px;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
* Input
*/
.select-search__input {
    display: block;
    height: 70px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: #0885bf 0px 0px 0px 2px;
    border-radius: 3px;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
    list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2fcc8b;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
* Group
*/
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
.select-search__value::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

/**responsive css kodlari*/

@media only screen and (max-width: 768px) {
    .select-search__input {
        display: block;
        height: 50px;
        width: 100%;
        padding: 0 1rem;
        background: #fff;
        border: 1px solid transparent;
        box-shadow: #0885bf 0px 0px 0px 2px;
        border-radius: 0.6rem;
        outline: none;
        font-family: "Noto Sans", sans-serif;
        font-size: 0.875rem;
        text-align: left;
        text-overflow: ellipsis;
        line-height: 2rem;
        -webkit-appearance: none;
    }
}

.hour-area{
    display: flex;
    padding : 1%;
    flex-wrap: wrap;
}

.hour-item{
    width: 9%;
    height: 50px;
    background-color: lightseagreen;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    margin: 1% 2% 0 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.hour-item-disabled{
    width: 9%;
    height: 50px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    margin: 1% 2% 0 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.hour-item-selected{
    width: 9%;
    height: 50px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    margin: 1% 2% 0 0;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.hour-item span{
    color: white;
    font-weight: bold;
}

.please-text{
    margin: 1%;
    font-weight: bold;
    color: black;
    font-style: italic;
    font-size: 14px;
}

.loading-div{
    display: flex;
    justify-content: center;
}

.hour-item:hover{
    background-color: black;
}

.hour-item span:hover{
    background-color: black;
    cursor: pointer;
}

/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
    .hour-item{
        width: 12%;
    }
    .hour-item-disabled{
        width: 12%;
    }
    .hour-item-selected{
        width: 12%;
    }
}

@media only screen and (max-width: 768px) {
    .hour-area{
        justify-content: center;
    }
    .hour-item{
        width: 25%;
    }
    .hour-item-disabled{
        width: 25%;
    }
    .hour-item-selected{
        width: 25%;
    }
}

.doctor-box-span{
    display: flex;
    flex:1 1;
    text-align:center;
    margin:1%;
}

.doctor-box-span-text{
    color:white;
    font-family: "monospace";
}

.doctor-box-div{
    box-shadow: rgba(0, 0, 0, 1) 2px 5px 15px;
    display: flex;
    width:22%;
    background-color: #0885bf;
    flex-direction: column;
    margin: 1%;
    padding: 1%;
    border-radius: 8%;
    flex-wrap: wrap;
}

/**responsive css kodlari*/

@media only screen and (max-width: 1600px) {
    .doctor-box-div{
        width: 30%;
    }
}

@media only screen and (max-width: 992px) {
    .doctor-box-div{
        width: 40%;
    }
}

@media only screen and (max-width: 768px) {
    .doctor-box-div{
        width: 100%;
    }
}

.dialog-div{
    width: 50vw;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1600px) {
    .dialog-div{
        width: 60vw;
    }
}

@media only screen and (max-width: 992px) {
    .dialog-div{
        width: 80vw;
    }
}

@media only screen and (max-width: 768px) {
    .dialog-div{
        width: 100%;
    }
}

.appointment-card {
  border: 1px solid #0785c7;
  border-radius: 12px;
}

.doctor-name-label {
  font-size: 1.2em;
  font-weight: 700;
}

.doctor-contact-info-label {
  font-size: .9em;
  font-weight: 300;
}

.appointment-list-wrapper {
  height: 90%;
  overflow: auto;
}

.appointment-dialog{
  width: 65vw;
  height: 50vw;
}

.p-button{
  background: #0885bf !important;
  border: 1px solid white !important;
  color: white !important;
}


.p-tag{
  background: #0885bf !important;
}


/**responsive css kodlari*/

@media only screen and (max-width: 1200px) {
  .appointment-dialog{
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .appointment-dialog{
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .appointment-dialog{
    width: 100%;
    height: 100%;
  }
}

.user-actions {
  border-bottom: 1px solid #0785c7;
  padding-bottom: 8px;
}

.p-inputtext, .p-inputnumber, .p-dropdown {
  width: 100%;
}

.p-datepicker-current-day{
 background-color: red !important;
}

.aboutpage {
  margin: 0;
  padding-bottom: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
}

.aboutsec1 {
  margin: 100px 0 0 0;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.aboutsec1info {
  width: 50%;

}

.aboutsec1info p {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

.about-title-1 {
  font-family: "Cocogoose Pro Block";
 text-align: left;
  color: #0785c7;
}

.about-title-2 {
  font-family: "Cocogoose Pro Block";
  color: #0785c7;
  font-size: 48px;
}

.about-title-3 {
  font-family: "Cocogoose Pro Block";
  color: #fff;
  font-size: 36px;
}

.aboutsec2li {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

.about-title-4 {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

.about-title-5 {
  font-family: "Cocogoose Pro Block";
  color: #0785c7;
}
.about-title-6 {
  font-family: "Cocogoose Pro Block";
  color: #0785c7;
}

#aboutsec1img {
  width: 25%;
  margin-left: 5%;
}

.aboutsec2 {
  margin: 0 0 50px 0;
  padding: 100px;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/aboutsecbackground.fcb640f4.png);
  background-size: cover;
  background-position-y: top;
}

.aboutsec2li {
  text-align: justify;
  margin-bottom: 10px;
}

#aboutsec2img {

  width: 25%;
}

#aboutsec2info {
   width: 75%;
  color: white;
}

.aboutsec3 {
  margin: 0;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#aboutsec3info {
  width: 50%;
  margin-right: 5%;
}

#aboutsec3info p {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
}

#aboutsec3img {
  width: 50%;
}

.aboutsec4 {
  margin: 0;
  text-align: justify;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

#aboutsec4info {
  width: 50%;
  margin-left: 5%;
  padding-bottom: 180px;
}

#aboutsec4info p {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 900;
  left: 30px;
}

#aboutsec4img {
  width: 400px;
}



@media (min-width: 2000px) {
  body {
    font-size: 120%;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  body {
    font-size: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  body {
    font-size: 90%;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  body {
    font-size: 80%;
  }



}
@media (min-width: 300px) and (max-width:800px){
  #aboutsec3img {
    width: 150px;
  }
  #aboutsec4img {
    width: 150px;
  }

}

@media (max-width: 800px) {
  body {
    font-size: 75%;
  }
  #aboutsec2img {

    display: none;
  }
  #aboutsec2info {
    margin-top: 50px;
    width: 100%;
   color: white;
 }
}
@media (min-width: 300px) and (max-width:800px){
  .about-title-2 {

    font-size: 25px;
    text-align: left;
  }
}

.contactsec {
  font-family: "monospace";
  color: #0785c7;
  display: flex;
  padding-top: 75px;
  justify-content: center;
  align-items: flex-start;
}

.contactimg {
  width: 25%;
  padding-top: 8%;
  margin: 5%;
}

.contactborderimg {
  margin: 0 auto;
  margin-bottom: 25px;
}

.contactformbase {
  width: 30%;
  height: 25%;
  padding: 30px 15px 30px 15px;
  margin-bottom: 125px;
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contactformh3 {
  margin-top: 0;
  font-weight: 900;
}

.contactform {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contactforminput {
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  margin: 0 0 10px 0;
  padding: 0.4em;
  text-align: center;
}

.div_contactform_namemail {
  width: 100%;
}
.contactformemailinput {
  width: 100%;
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  margin: 0 0 10px 0;
  padding: 0.4em;
}

.contactformmessageinput {
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 2em;
  border: 3px solid #0785c7;
  border-radius: 2.5em;
  margin: 0 0 10px 0;
  padding: 7em;
  text-align: center;
}

.contactformbutton {
  font-family: "monospace";
  font-weight: bold;
  padding: 4px 8% 4px 8%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border: 3px solid #0785c7;
  border-radius: 0.8em;
  color: #0785c7;
  background-color: white;
  font-weight: bold;
}

@media (min-width: 2000px) {
  body {
    font-size: 120%;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  body {
    font-size: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  body {
    font-size: 90%;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  body {
    font-size: 80%;
  }
}

@media (max-width: 800px) {
  body {
    font-size: 75%;
  }
}
@media only screen and (max-width: 924px) {
  .contactimg {
    display: none;
  }
  .contactformbase {
    width: 80%;
  }
}

.slider-container{
  display: flex;
  background-color: #0885bf;
  flex-direction: column;
  min-height: 28vw;
  margin-top: 5em;
}

.slider-header{
  text-align: center;
  font-size: 7vh;
  font-family: "Cocogoose Pro Block";
  color: #fff;
  height: 10%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.box-area{
  display: flex;
  height: 90%;
  justify-content: space-between;
  flex-direction: row;
  margin: 3em 10% 1em 10%;
}

.left-angle-div{
  display: flex;
  width:3%;
  justify-content: center;
  align-items: center;
}

.right-angle-div{
  display: flex;
  width:3%;
  justify-content: center;
  align-items: center;
}

.slider-box{
  display: flex;
  box-sizing: border-box;
  width:22%;
  min-height: 250px;
  padding: 1em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 7%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.detail{
  display: flex;
  height: 400px;
  max-height: 400px;
  width : 100%;
  flex-direction: column;
}

.select-button{
  color:white;
  font-family: "monospace";
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}

.detail p {
  word-wrap: break-word;
  color: #fff;
  font-size: 14px;
}

.iconwomen {
  width: 5em;
  height: 5em;
}


.angle-icon-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.angle-icon{
  color: white;
}

/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
  .box-area{
    margin-top: 1em;
  }
  .slider-box{
    width:40%
  }
}

@media only screen and (max-width: 768px) {
  .box-area{
    margin-top: 1em;
  }
  .slider-box{
    width:80%;
  }
  .slider-header{
    font-size: 6vw;
  }
}

.flow-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}


.branches-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  flex-direction: column;
  min-height: 20vw;
}

.branches-text-area{
  display: flex;
  height: 10%;
}

.branches-box-area{
  display: flex;
  height: 90%;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1%;
}

.branches-box{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 132, 219);
  border-radius: 37px;
  width: 20%;
  flex-direction: column;
  margin-top: 1em;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.branches-box h4{
  font-family: "monospace";
  color: #fff;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}

.branches-box p{
  display: flex;
  color: #fff;
  padding: 5%;
}

.branches-title{
  display: flex;
  font-family: "Cocogoose Pro Block";
  font-size: 3em;
  text-align: left;
  color: #0885bf;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1210px) {
  .branches-box-area{
    flex-wrap: wrap;
  }
  .branches-box{
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .branches-box-area{
    flex-direction: column;
    align-items: center;
  }
  .branches-box{
    width:80%;
    margin-top: 1em;
  }

  .branches-title{
    font-size: 2em;
  }
}

.comments-container {
  display: flex;
  background-color: #fff;
  min-height: 50vw;
}

.comments-box-area {
  border: 6px rgb(0, 132, 219) solid;
  background-color: #fff;
  border-radius: 37px;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  margin-top: 5em;
  padding: 2em;
}


.comments-box-area h2 {
  color: #0885bf;
  font-family: "monospace";
  font-weight: bolder;
  font-size: 33px;
}


.comments-box {
  display: flex;
  color: #fff;
  font-family: "monospace";
  font-weight: bold;
  font-size: large;
  border-radius: 37px;
  background-color: #0885bf;
  height:100px;
  margin:1em;
  width: 75%;
}

.comments-box h3 {
  font-family: "monospace";
  display: flex;
  margin-left: 25%;
  margin-top: 0%;
}

.comments-box p {
  font-family: "monospace";
  padding-left: 10%;
  display: flex;
  font-size: small;
}


/*right side css kodları*/

.right-side{
  width: 20%;
  background-image: url(/static/media/bg-2.0015fe26.png);
  background-size: 100% 100%;
}

/**responsive css kodlari*/

@media only screen and (max-width: 1210px) {
  .center-side{
    flex: 1 1;
  }
  .content-area .img-area{
    display: none;
  }
  .doctor-img{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .comments-box{
    flex-direction: column;
    height: 150px;
    width: 90%;
  }
  .doctor-img{
    display: none;
  }
}

.media-container{
    display: flex;
    flex-direction: column;
    min-height: 35vw;
    background-color: #0885bf;
    margin-top: 10em;
}

.media-title-area{
    display: flex;
    height: 10%;
    justify-content: center;
    position: relative;
    border-bottom : 1px solid white;
}

.media-slider-area{
    display: flex;
    height: 90%;
    padding: 5vw;
}

.media-slider-area .items {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex:1 1;
    margin-left: 15%;
    margin-right: 15%;
    -webkit-animation: carouselAnim 4s infinite alternate linear;
            animation: carouselAnim 4s infinite alternate linear;
}

.media-slider-area .items .entry {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 23%;
}

.media-slider-area .items .entry img {
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

@-webkit-keyframes carouselAnim {
    from {
        -webkit-transform: translate(-10%, 0);
                transform: translate(-10%, 0);
    }
    to {
        -webkit-transform: translate(calc(10%));
                transform: translate(calc(10%));
    }
}

@keyframes carouselAnim {
    from {
        -webkit-transform: translate(-10%, 0);
                transform: translate(-10%, 0);
    }
    to {
        -webkit-transform: translate(calc(10%));
                transform: translate(calc(10%));
    }
}

.media-container .faders {
    width: 100%;
}

.media-title-area .media-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-family: "Cocogoose Pro Block";
    font-size: 10vw;
    opacity: 0.3;;
}

.media-title-area .media-title2 {
    display: flex;
    font-family: "monospace";
    font-size: 5vw;
    color: #fff;
    font-weight: 900;
    position: absolute;
    left: 45%;
    top: 20%;
}

.media-title-area .bgheart{
    display: flex;
}

.media-title-area .bgheart img {
    position: absolute;
    top: -45%;
}

@media only screen and (max-width: 768px) {
    .media-container{
        margin-top: 10vw;
    }

    .media-slider-area .items .entry {
        width: 24%;
    }

    .media-slider-area{
        padding: 5vw;
    }
}

.advantage-container {
    display: flex;
    background-color: #fff;
    flex-direction: column;
}

.advantage-title {
    display: flex;
    height: 10%;
    justify-content: space-evenly;
    padding: 5%;
}

.advantage-content {
    display: flex;
    height: 85%;
}

.advantage-button-area {
    margin-top: 2em;
    display: flex;
    height: 5%;
    justify-content: center;
}

.advantage-image-area {
    display: flex;
    width: 40%;
    justify-content: flex-end;
    margin-right: 5%;
    position: relative;
}

.advantage-text-area{
    display: flex;
    width: 60%;
}

.advantage-title h1 {
    font-family: "Cocogoose Pro Block";
    font-size: 50px;
    text-align: left;
    color: #0885bf;
}

.advantage-image-area .advantage-img {
    position: absolute;
    display: flex;
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
    top: 40%;
    left: 18%;
    width: 40%;
}

.advantage-image-area .advantage-box {
    display: flex;
}

.advantage-image-area .advantage-box img{
    width: 20em;
    border: 4px  rgb(0, 132, 219) solid;
    border-radius: 30px;
}

.text-box{
    display: flex;
    width: 40%;
    height: 50%;
}

.text-box2{
    font-family: "monospace";
    font-size: 100%;
    margin-right: 3%;
}
.text-box3{
    font-family: "monospace";
    font-size: 100%;
}
.text-header{
    font-size: 120%;
    color: rgb(4, 133, 224);
    font-weight: bold;
}
.line{
    color: rgb(4, 133, 224);
    font-weight: bold;
}

.advantage-button-area .button-1{
    border-radius: 1rem;
    background-color: #1a98d5;
    color: #ffff;
    font-weight: bold;
    font-family: "monospace";
    border: none;
    height: 3em;
    padding-left: 1rem;
    padding-right: 1rem;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1210px) {
    .advantage-image-area .advantage-img {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .advantage-image-area .advantage-box {
        height: 40%;
    }
    .advantage-image-area .advantage-img {
        display: none;
    }
    .advantage-title h1 {
        font-size: 22px;
    }
}

input:focus{
    outline: none;
}

.content1-container{
    display: flex;
    background-color: #0885bf;
    min-height: 42vw;
}

/*left side css kodları*/

.left-side{
    width: 20%;
}

/*center side css kodları*/

.center-side{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.input-area{
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-area input{
    padding: 10px 20px;
    border-radius: 2em;
    font-size: 19px;
    z-index: 1;
    width: 50vw;
    border: none;
}

.input-area div{
    position: relative;
}

.input-area button{
    position: absolute;
    width: 50px;
    padding: 0.78em;
    border-radius: 50%;
    right: 0;
    top:50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    border: 2px solid #fff;
    background: #0885bf;
    cursor: pointer;
    z-index: 1;
}

.input-area button .fas{
    color: #fff;
}

.content-area{
    height: 80%;
    background-image: url(/static/media/bg1.3f3a082b.png);
    background-size:100% 100%;
    display: flex;
    margin-top: 1em;
    padding: 2em;
}

.content-area .img-area{
    background-image: url(/static/media/bg3.fb8e024d.png);
    display: flex;
    flex:1 1;
    background-size:100% 100%;
    background-position: top;
    background-size: cover;
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    z-index: 15;
}

.content-area .login-area{
    flex:1 1;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

/*right side css kodları*/

.right-side{
    width: 20%;
    background-image: url(/static/media/bg-2.0015fe26.png);
    background-size: 100% 100%;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1210px) {
    .center-side{
        flex: 1 1;
    }
    .content-area .img-area{
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .content-area{
        padding: 0;
    }
    .center-side{
        flex: 1 1;
    }
    .content-area .img-area{
        display: none;
    }
    .input-area{
        margin-top: 5%;
    }
    .input-area input{
        width: 80vw;
        font-size: 15px;
    }
    .input-area button{
        width: 40px;
    }
    .left-side{
        display: none;
    }
    .right-side{
        display: none;
    }
}

.rotateZ270 {
    -webkit-transform: rotateZ(270deg);
            transform: rotateZ(270deg);
}

@-webkit-keyframes flip-with-scale {
    0% {
        -webkit-transform: perspective(400px) scaleX(1);
                transform: perspective(400px) scaleX(1);
    }

    100% {
        -webkit-transform: perspective(400px) scaleX(-1);
                transform: perspective(400px) scaleX(-1);
    }
}

@keyframes flip-with-scale {
    0% {
        -webkit-transform: perspective(400px) scaleX(1);
                transform: perspective(400px) scaleX(1);
    }

    100% {
        -webkit-transform: perspective(400px) scaleX(-1);
                transform: perspective(400px) scaleX(-1);
    }
}

@-webkit-keyframes flip-with-rotate {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
                transform: perspective(400px) rotateY(0);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(180deg);
                transform: perspective(400px) rotateY(180deg);
    }
}

@keyframes flip-with-rotate {
    0% {
        -webkit-transform: perspective(400px) rotateY(0);
                transform: perspective(400px) rotateY(0);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(180deg);
                transform: perspective(400px) rotateY(180deg);
    }
}
.App {
  background-color: white;
}

.Form {
  width: 460px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #ffff;
  border-radius: 2rem;
}

.Form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Form-title {
  font-family: "monospace";
  text-align: left;
  margin-bottom: 1em;
  font-size: 19px;
  color: #1a98d5;
  font-weight: 800;
  margin: 1px;
  padding: 1px;
}

label {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.Form-control {
  width: 320px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.5rem;
  background-color: white;
  margin: 0.5rem;
}

.Form-control::-webkit-input-placeholder {
  padding: 15px;
}

.Form-control::placeholder {
  padding: 15px;
}

.login-btn {
  margin: 5px;
  border-radius: 1rem;
  width: 136px;
  height: 34px;
  background-color: #1a98d5;
  color: #ffff;
  font-weight: 600;
  font-family: "monospace";
  border: none;
}

.login-button-div {
  display: flex;
  flex: 3 1;
  flex-direction: column;
  align-items: center;
}

/*.Rectangle_9_copy_2 {
  border-radius: 11px;
  background-color: rgb(8, 133, 192);
  box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.18);
  position: absolute;
  left: 1221px;
  top: 577px;
  width: 136px;
  height: 34px;
  z-index: 51;
}
 */

.Forgot-password {
  position: relative;
  text-align: center;
  font-family: "monospace";
}
.Forgot-password span {
  text-decoration: none;
  color: #1295d6;
  cursor: pointer;
}
.Form-control {
  border: none;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.4);
  outline-width: 1px;
}

.login-forms {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  top: -5%;
}

/**responsive css kodlari*/
@media only screen and (max-width: 924px) {
  .Form {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .Form {
    width: 400px;
  }
}

.Signup-Form {
    width: 460px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: #ffff;
    border-radius: 3rem;
}

.SignupForm-content {
    padding-left: 12%;
    padding-right: 12%;
}

.info-p{
    --tw-text-opacity: 1;
    color: rgb(93 93 93/var(--tw-text-opacity));
    font-size: .80rem;
    line-height: 1rem;
}

.aggrement-a{
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    --tw-text-opacity: 1;
    color: rgb(24 78 119/var(--tw-text-opacity));
    font-size: .75rem;
    line-height: 1rem;
}

.SignupForm-title {
    text-align: left;
    margin-bottom: 1rem;
    font-size: 19px;
    font-family: "monospace";
    color: #1a98d5;
    font-weight: 800;
    margin: 1px;
    padding: 1px;
}

.SignupForm-control {
    width: 320px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0.5rem;
    background-color: white;
    margin: 0.5rem;
    border: none;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.4);
    outline-width: 1px;
}

.SignupForm-control::-webkit-input-placeholder {
    padding: 15px;
}

.SignupForm-control::placeholder {
    padding: 15px;
}

.Signup-btn {
    border-radius: 1rem;
    width: 136px;
    height: 34px;
    background-color: #1a98d5;
    color: #ffff;
    font-weight: 600;
    font-family: "monospace";
    font-size: 17px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-forgot-password {
    position: relative;
    text-align: center;
    font-family: "monospace";
    text-decoration: none;
}

.signup-forgot-password span {
    text-decoration: none;
    color: #1295d6;
}

.signup-button-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control-not-block{
    display: flex !important;
}

.p-card-content{
    padding:0em !important;
}

.p-card-body{
    padding:0 !important;
}

.resultText{
    line-height: 35px;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black
}

.p-divider{
    background-color: #0885bf;
}



header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 .5rem;
  background-color: #fff;
  color: #1a98d5;
  border-bottom: 1px solid #1a98d5;
}

nav a {
  margin: 0 2rem;
  color: #1a98d5;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: "DIN[T]", sans-serif;
  font-weight: 900;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #1a98d5;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}
.navbar-profile ul.nav > li > a {
  color: #1a98d5;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 20px;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: "DIN[T]", sans-serif;
  font-weight: 900;
}

.doctor-register-btn {
  background-color: #1a98d5;
  border-radius: 1rem;
  color: white;
  font-family: "DIN[T]", sans-serif;
  font-size: 18px;
  font-weight: bolder;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.doctor-register-btn:hover {
  color: #dcf3ff;
}

.navbar-profile ul.nav > li .dropdown-menu {
  right: 0;
  left: auto;
}
.dropdown-item {
  font-size: 1rem;
  font-family: "DIN[T]", sans-serif;
  color: #1a98d5;
}

@media (max-width: 767px) {
  .navbar-profile {
    display: inline-block;
    width: auto;
    text-align: center;
    margin: auto;
  }
}

@media only screen and (max-width: 924px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #1a98d5;
    z-index: 2000;
    transition: 1s;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
  .doctor-register-btn {
    padding: 0;
    font-size: 24px;
    margin: 0 0;
  }

  header .responsive_nav {
    -webkit-transform: none;
            transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    color: #fff;
  }

  nav a {
    font-size: 1.5rem;
    color: #fff;
  }
}

html {
  font-size: 0.9rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: #fff;
  --textColor: #1a98d5;
}

.main-blue-color{
  color: #1a98d5;
}

.main-white-color{
  color: #fff;
}

html:-webkit-scrollbar {
  width: 11px;
}

html:-webkit-scrollbar-track {
  background: #fff;
  background: var(--mainColor);
}

*:-webkit-scrollbar-thumb {
  background-color: #1a98d5;
  background-color: var(--textColor) ;
  border-radius: 6px;
}

.main-sperator{

  width: 100%;
  height: 0.1rem;
  min-height: 0.5px;
  background-color: #1a98d5;
  border-radius: 2px;
}


@font-face {
  font-family: "monospace";
  src: url(/static/media/DINT.cec6ef8d.woff2) format("woff2"),
    url(/static/media/DINT.cec6ef8d.woff2) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cocogoose Pro Block";
  src: url(/static/media/CocogooseProBlock-Border.0ddd532b.woff2) format("woff2"),
    url(/static/media/CocogooseProBlock-Border.66e67247.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Avant Garde Gothic LT";
  src: url(/static/media/AvantGardeLT-Medium.9446bf62.woff2) format("woff2"),
    url(/static/media/AvantGardeLT-Medium.1b28a4f6.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  outline: #1a98d5;
}

.main-footer {
  background-image: url(/assets/img/footerbackground.png);
}

.footer-widget ul {
  padding-left : 0 !important;
}

.text {
  font-family: "monospace";
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.7em;
  margin-top: 15px;
}

.footer-list {
  padding: 0;
}

.footer-list li a {
  font-family: "monospace";
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.7em;
  font-weight: 500;
  text-decoration: none;
}
.footer-list li {
  list-style: none;
}

.main-footer h2 {
  font-family: "monospace";
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.main-footer .logo img {
  width: 130px;
  height: auto;
}
.list-style-two {

}

.list-style-two li .icon {
  top: 0;
  color: #ffffff;
  font-size: 18px;
  line-height: 0.7em;
}

.list-style-two li a {
  font-family: "monospace";
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
}
.list-style-two li {
  list-style: none;
}

.footer-widget .social-box li {
  line-height: 1em;
  padding-left: 0;
  color: #fff;
  font-size: 30px;
  padding-right: 12px;
  display: inline-block;
}

.footer-widget .social-box {
  float: left;
}

.social-box li a {
  text-decoration: none;
  color: #ffffff;
}

.social-box li .icon {
  font-size: 40px;
  position: absolute;
  color: #ffffff;
  left: 0px;
  top: 0;
}

.app-box li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.app-box img {
  max-width: 100%;
  height: auto;
}

.footerContainer{
  position: relative;
  top: 200px
}

.mt-200custom{
  margin-top:200px;
}

@media only screen and (max-width: 767px) {
  .main-footer{
    margin-top: 10px
  }

  .first-container {
    margin-top: 300px !important;
  }

  .footer-widget {
    margin-top: 0px !important;
  }

  .main-footer {
    background-color: #0585BF !important;
    padding-bottom: 35px !important;
  }

  .footerContainer{
    top: 10px !important;
  }

  .mt-200custom{
    margin-top:10px !important;
  }

}

.login-page-section {
  padding: 200px 0 50px;
  position: relative;
}
.login_form {
  display: flex;
  align-content: center;
  justify-content: center;
}

.login-img {
  position: absolute;
  content: "";
  right: 55%;
  bottom: 40px;
  width: 469px;
  height: 310px;
  background-repeat: no-repeat;
  background-size: contain;
}
.login-img1 {
  position: absolute;
  content: "";
  left: 70%;
  top: 100px;
  width: 29%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}

.login-img2 {
  position: absolute;
  content: "";
  right: 75%;
  bottom: 40px;
  width: 469px;
  height: 310px;
  background-repeat: no-repeat;
  background-size: contain;
}
.login-logo {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 1%;
}

@media only screen and (max-width: 924px) {
  .login-img {
    display: none;
  }
  .login-img2 {
    display: none;
  }
  .login-img1 {
    display: none;
  }
}

.doctor-box-span{
    display: flex;
    flex:1 1;
    text-align:center;
    margin:1%;
}

.doctor-box-span-text{
    color:white;
    font-family: "monospace";
}

.doctor-box-span-text-detay{
    color:white;
    font-family: "monospace";
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}

.doctor-box-span-text-detay:hover{
    cursor:pointer;
    opacity : 0.9;
}


/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
    #doctor-box-home-page-div{
        width: 30% !important;
    }
}

@media only screen and (max-width: 768px) {
    #doctor-box-home-page-div{
        width: 70% !important;
    }
}

.emergency-doctor {
  position: fixed;
  right: 15px;
  bottom: 110px;
  display: block;
  cursor:pointer;
  z-index: 999999;
}

.whatsapp {
  position: fixed;
  right: 15px;
  bottom: 0px;
  display: block;
  cursor:pointer;
  z-index: 999999;
}

.whatsapp img {
  width: 100px;
  height: 100px;
}
.mobile-bottom{
  width: 100%;
  position: fixed;
  padding: 5px 0;
  z-index: 100;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 15px rgb(0 0 0 / 25%);
  display: none;
  text-align: center;
}
.noLogin{
  display: block;
}

.emergency-doctor-btn{
  height: 40px;
  width: 100%;
  background-color: #e0191a;
  border-radius: 20px;
}
.mobile-bottom .emergency-doctor-btn {
  position: relative;
  right: auto;
  left: auto;
  bottom: auto;
}
.emergency-doctor-btn button{
  text-align: center;
  background: transparent;
  border:none;
}

.emergency-doctor-btn p {
  height: 40px;
  width: 100%;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  color:#fff;
}

@media only screen and (max-width: 991px) {
  .mobile-bottom {
    display: block;
  }
  .emergency-doctor{
    display: none;
  }
  .whatsapp img {
    width: 60px;
    height: 60px;
    display: inline-block;
    max-width: 100%;
    right: 5px;
    bottom: 55px;
    position: fixed;
  }
}

@media only screen and (max-width: 479px) {
  .mobile-bottom .emergency-doctor-btn{
    display: block;
  }
}
.doctor-voting-card{
    width: 50%;
}

.doctor-voting-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rating-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

.rating-container span label{
    font-size: 1.875rem !important;
    color: #faaf00 !important;
}

.info-div label{
    font-size: 1.2rem;
    padding: 0.5rem;
}

.p-card-title{
    font-size: 1.5em !important;
    text-align: center;
    text-decoration: underline;
    color: black !important;
}

.vote-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

/**responsive css kodlari*/
@media only screen and (max-width: 924px) {
    .doctor-voting-card{
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .doctor-voting-card{
        width: 100%;
    }
}

.main-content {
    padding-top: 100px;
    padding-bottom: 100px;
}

.flex-center {
    align-items: center;
}
.accordion-button{
    margin-bottom: 10px;
}
.accordion-body {
    margin-top: 15px;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}
.circle-icon {

    height: 50px;
    width: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a98d5;
    color: #fff;
    margin-left: -20px;
    margin-right: 10px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.accordion-item{
    border: 0px!important;
}
.accordion-button:not(.collapsed){
    border: 0px!important;
    color: #1a98d5;
    background-color: #ffffff;
    box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
}

.faq-title{
    font-family: "Cocogoose Pro Block", serif;
    color: #1a98d5;
    font-weight: bold;
    text-align: center;
}

.question-title{
    font-family: "DIN[T]", serif;
    font-size: 24px;

}
.payment-container{
    display:flex;
    justify-content: center;
    margin-top: 5rem;
}
.payment-status{
    display:flex;
    flex-direction: column;
    border:solid #0a58ca 2px;
    border-radius:1em ;
    width:30rem;
    justify-content: center;
    padding: 1%;
}
.payment-img{
    align-items: center;
    justify-content: center;
    display:flex;
    justify-content: center;
}

.payment-text{
    display:flex;
    font-family: "monospace";
    text-align: center;
    font-size:25px;
    font-weight: bold;
    color:#0a58ca;
    justify-content: center;
}

.payment-text1{
    font-family: "monospace";
    font-weight: bolder;
    color:#0a58ca;
    text-align: center;
    display:flex;
    justify-content: center;
}

h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight:bold;
}

h2 {
    font-weight:bold;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-block: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.card {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ece9e6;
  background: linear-gradient(to right, #ffffff, #ece9e6);
  cursor: pointer;
}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #0785c7;
  font-family: "DIN[T]", serif;
}

.tag {
  align-self: flex-start;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.75rem;
}

.tag + .tag {
  margin-left: 0.5em;
}

.tag-blue {
  background: #56ccf2;
  background: linear-gradient(to bottom, #2f80ed, #56ccf2);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.user__image {
  border-radius: 60%;
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.user__info > small {
  color: #666;
}

.blog-title {
  font-family: "Cocogoose Pro Block", serif;
  font-size: 5vh;
  color: #1a98d5;
  text-align: center;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-block: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.card {
  display: flex;
  flex-direction: column;
  width: clamp(20rem, calc(20rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ece9e6;
  background: linear-gradient(to right, #ffffff, #ece9e6);
  cursor: pointer;
}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #0785c7;
  font-family: "DIN[T]", serif;
}

.tag {
  align-self: flex-start;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.75rem;
}

.tag + .tag {
  margin-left: 0.5em;
}

.tag-blue {
  background: #56ccf2;
  background: linear-gradient(to bottom, #2f80ed, #56ccf2);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.user__image {
  border-radius: 60%;
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.user__info > small {
  color: #666;
}

.blog-title {
  font-family: "Cocogoose Pro Block", serif;
  font-size: 7vh;
  color: #1a98d5;
  text-align: center;
}

.pages-content {
  margin: 50px 0px;
  margin-bottom: 100px;
}
.page-content {
  background: #fff;
  padding: 25px 20px;
}
.page-content h2 {
  font-family: "monospace";
  color: #0785c7;
  text-align: center;
  font-size: 32px;
}

.content-img {
  float: right;
  margin-bottom: 33px;
  margin-left: 32px;
}
.pages-content-header p {
  font-family: "monospace";
  font-size: 18px;
}

.pages-content-header img {
  max-width: 100%;
}

.branchsearch-container{
    display: flex;
    background-color: #0885bf;
    flex-direction: column;
    min-height: 40vw;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.branchsearch-slider-box{
    display: flex;
    box-sizing: border-box;
    width:22%;
    min-height: 250px;
    padding: 1em;
    margin: 1%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 7%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.branchsearch-box-area{
    display: flex;
    height: 90%;
    justify-content: center;
    flex-direction: row;
    margin: 3em 10% 1em 10%;
    flex-wrap: wrap;
}

/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
    .branchsearch-box-area{
        margin-top: 1em;
    }
    .branchsearch-slider-box{
        width:40%
    }
}

@media only screen and (max-width: 768px) {
    .branchsearch-box-area{
        margin-top: 1em;
    }
    .branchsearch-slider-box{
        width:60%;
    }
    .slider-header{
        font-size: 6vw;
    }
}

.main-contanier {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
} 

