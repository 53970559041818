

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 .5rem;
  background-color: #fff;
  color: #1a98d5;
  border-bottom: 1px solid #1a98d5;
}

nav a {
  margin: 0 2rem;
  color: #1a98d5;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: "DIN[T]", sans-serif;
  font-weight: 900;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #1a98d5;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}
.navbar-profile ul.nav > li > a {
  color: #1a98d5;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 20px;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: "DIN[T]", sans-serif;
  font-weight: 900;
}

.doctor-register-btn {
  background-color: #1a98d5;
  border-radius: 1rem;
  color: white;
  font-family: "DIN[T]", sans-serif;
  font-size: 18px;
  font-weight: bolder;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.doctor-register-btn:hover {
  color: #dcf3ff;
}

.navbar-profile ul.nav > li .dropdown-menu {
  right: 0;
  left: auto;
}
.dropdown-item {
  font-size: 1rem;
  font-family: "DIN[T]", sans-serif;
  color: #1a98d5;
}

@media (max-width: 767px) {
  .navbar-profile {
    display: inline-block;
    width: auto;
    text-align: center;
    margin: auto;
  }
}

@media only screen and (max-width: 924px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #1a98d5;
    z-index: 2000;
    transition: 1s;
    transform: translateY(-100vh);
  }
  .doctor-register-btn {
    padding: 0;
    font-size: 24px;
    margin: 0 0;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    color: #fff;
  }

  nav a {
    font-size: 1.5rem;
    color: #fff;
  }
}
