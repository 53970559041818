.emergency-doctor {
  position: fixed;
  right: 15px;
  bottom: 110px;
  display: block;
  cursor:pointer;
  z-index: 999999;
}

.whatsapp {
  position: fixed;
  right: 15px;
  bottom: 0px;
  display: block;
  cursor:pointer;
  z-index: 999999;
}

.whatsapp img {
  width: 100px;
  height: 100px;
}
.mobile-bottom{
  width: 100%;
  position: fixed;
  padding: 5px 0;
  z-index: 100;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 15px rgb(0 0 0 / 25%);
  display: none;
  text-align: center;
}
.noLogin{
  display: block;
}

.emergency-doctor-btn{
  height: 40px;
  width: 100%;
  background-color: #e0191a;
  border-radius: 20px;
}
.mobile-bottom .emergency-doctor-btn {
  position: relative;
  right: auto;
  left: auto;
  bottom: auto;
}
.emergency-doctor-btn button{
  text-align: center;
  background: transparent;
  border:none;
}

.emergency-doctor-btn p {
  height: 40px;
  width: 100%;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
  color:#fff;
}

@media only screen and (max-width: 991px) {
  .mobile-bottom {
    display: block;
  }
  .emergency-doctor{
    display: none;
  }
  .whatsapp img {
    width: 60px;
    height: 60px;
    display: inline-block;
    max-width: 100%;
    right: 5px;
    bottom: 55px;
    position: fixed;
  }
}

@media only screen and (max-width: 479px) {
  .mobile-bottom .emergency-doctor-btn{
    display: block;
  }
}