.sidebar {
  border-right: 1px solid #0785c7;
}

.profile-photo {
  border-radius: 100%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  border: 1px solid #0785c7;
  width: 100px !important;
}

.profile-photo-sidebar {
  border-radius: 100%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  min-width: 180px !important;
  max-width: 200px !important;
  border: 1px solid #0785c7;
}

.profile-photo-inline {
  width: 100%;
  min-width: 180px !important;
  max-width: 200px !important;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {

  .sidebar{
    justify-content: center;
  }

  .mjustify-center{
    justify-content: center;
  }

  .mtext-center{
    text-align: center;
  }

}
