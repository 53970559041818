html {
  font-size: 0.9rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: #fff;
  --textColor: #1a98d5;
}

.main-blue-color{
  color: #1a98d5;
}

.main-white-color{
  color: #fff;
}

html:-webkit-scrollbar {
  width: 11px;
}

html:-webkit-scrollbar-track {
  background: var(--mainColor);
}

*:-webkit-scrollbar-thumb {
  background-color: var(--textColor) ;
  border-radius: 6px;
}

.main-sperator{

  width: 100%;
  height: 0.1rem;
  min-height: 0.5px;
  background-color: #1a98d5;
  border-radius: 2px;
}


@font-face {
  font-family: "monospace";
  src: url("./font/DINT.woff2") format("woff2"),
    url("./font/DINT.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cocogoose Pro Block";
  src: url("./font/CocogooseProBlock-Border.woff2") format("woff2"),
    url("./font/CocogooseProBlock-Border.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ITC Avant Garde Gothic LT";
  src: url("./font/AvantGardeLT-Medium.woff2") format("woff2"),
    url("./font/AvantGardeLT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  outline: #1a98d5;
}
