.advantage-container {
    display: flex;
    background-color: #fff;
    flex-direction: column;
}

.advantage-title {
    display: flex;
    height: 10%;
    justify-content: space-evenly;
    padding: 5%;
}

.advantage-content {
    display: flex;
    height: 85%;
}

.advantage-button-area {
    margin-top: 2em;
    display: flex;
    height: 5%;
    justify-content: center;
}

.advantage-image-area {
    display: flex;
    width: 40%;
    justify-content: flex-end;
    margin-right: 5%;
    position: relative;
}

.advantage-text-area{
    display: flex;
    width: 60%;
}

.advantage-title h1 {
    font-family: "Cocogoose Pro Block";
    font-size: 50px;
    text-align: left;
    color: #0885bf;
}

.advantage-image-area .advantage-img {
    position: absolute;
    display: flex;
    transform: rotate(-8deg);
    top: 40%;
    left: 18%;
    width: 40%;
}

.advantage-image-area .advantage-box {
    display: flex;
}

.advantage-image-area .advantage-box img{
    width: 20em;
    border: 4px  rgb(0, 132, 219) solid;
    border-radius: 30px;
}

.text-box{
    display: flex;
    width: 40%;
    height: 50%;
}

.text-box2{
    font-family: "monospace";
    font-size: 100%;
    margin-right: 3%;
}
.text-box3{
    font-family: "monospace";
    font-size: 100%;
}
.text-header{
    font-size: 120%;
    color: rgb(4, 133, 224);
    font-weight: bold;
}
.line{
    color: rgb(4, 133, 224);
    font-weight: bold;
}

.advantage-button-area .button-1{
    border-radius: 1rem;
    background-color: #1a98d5;
    color: #ffff;
    font-weight: bold;
    font-family: "monospace";
    border: none;
    height: 3em;
    padding-left: 1rem;
    padding-right: 1rem;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1210px) {
    .advantage-image-area .advantage-img {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .advantage-image-area .advantage-box {
        height: 40%;
    }
    .advantage-image-area .advantage-img {
        display: none;
    }
    .advantage-title h1 {
        font-size: 22px;
    }
}
