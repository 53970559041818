.branches-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  flex-direction: column;
  min-height: 20vw;
}

.branches-text-area{
  display: flex;
  height: 10%;
}

.branches-box-area{
  display: flex;
  height: 90%;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1%;
}

.branches-box{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 132, 219);
  border-radius: 37px;
  width: 20%;
  flex-direction: column;
  margin-top: 1em;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.branches-box h4{
  font-family: "monospace";
  color: #fff;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}

.branches-box p{
  display: flex;
  color: #fff;
  padding: 5%;
}

.branches-title{
  display: flex;
  font-family: "Cocogoose Pro Block";
  font-size: 3em;
  text-align: left;
  color: #0885bf;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1210px) {
  .branches-box-area{
    flex-wrap: wrap;
  }
  .branches-box{
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .branches-box-area{
    flex-direction: column;
    align-items: center;
  }
  .branches-box{
    width:80%;
    margin-top: 1em;
  }

  .branches-title{
    font-size: 2em;
  }
}
