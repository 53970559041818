/* #root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-right: 0.4rem;
} */

.row {
  --bs-gutter-y: 0 !important;
  --bs-gutter-x: 0 !important;
}

.d-btn {
  text-transform: uppercase;
  border-radius: 24px !important;
  border-width: 2px !important;
  font-weight: 800 !important;
  margin-top: 1%;
  /* color: #58595b !important; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
