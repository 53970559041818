.comments-container {
  display: flex;
  background-color: #fff;
  min-height: 50vw;
}

.comments-box-area {
  border: 6px rgb(0, 132, 219) solid;
  background-color: #fff;
  border-radius: 37px;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  margin-top: 5em;
  padding: 2em;
}


.comments-box-area h2 {
  color: #0885bf;
  font-family: "monospace";
  font-weight: bolder;
  font-size: 33px;
}


.comments-box {
  display: flex;
  color: #fff;
  font-family: "monospace";
  font-weight: bold;
  font-size: large;
  border-radius: 37px;
  background-color: #0885bf;
  height:100px;
  margin:1em;
  width: 75%;
}

.comments-box h3 {
  font-family: "monospace";
  display: flex;
  margin-left: 25%;
  margin-top: 0%;
}

.comments-box p {
  font-family: "monospace";
  padding-left: 10%;
  display: flex;
  font-size: small;
}


/*right side css kodları*/

.right-side{
  width: 20%;
  background-image: url("../../assets/img/bg-2.png");
  background-size: 100% 100%;
}

/**responsive css kodlari*/

@media only screen and (max-width: 1210px) {
  .center-side{
    flex: 1;
  }
  .content-area .img-area{
    display: none;
  }
  .doctor-img{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .comments-box{
    flex-direction: column;
    height: 150px;
    width: 90%;
  }
  .doctor-img{
    display: none;
  }
}
