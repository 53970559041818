.slider-container{
  display: flex;
  background-color: #0885bf;
  flex-direction: column;
  min-height: 28vw;
  margin-top: 5em;
}

.slider-header{
  text-align: center;
  font-size: 7vh;
  font-family: "Cocogoose Pro Block";
  color: #fff;
  height: 10%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.box-area{
  display: flex;
  height: 90%;
  justify-content: space-between;
  flex-direction: row;
  margin: 3em 10% 1em 10%;
}

.left-angle-div{
  display: flex;
  width:3%;
  justify-content: center;
  align-items: center;
}

.right-angle-div{
  display: flex;
  width:3%;
  justify-content: center;
  align-items: center;
}

.slider-box{
  display: flex;
  box-sizing: border-box;
  width:22%;
  min-height: 250px;
  padding: 1em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 7%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.detail{
  display: flex;
  height: 400px;
  max-height: 400px;
  width : 100%;
  flex-direction: column;
}

.select-button{
  color:white;
  font-family: "monospace";
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}

.detail p {
  word-wrap: break-word;
  color: #fff;
  font-size: 14px;
}

.iconwomen {
  width: 5em;
  height: 5em;
}


.angle-icon-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.angle-icon{
  color: white;
}

/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
  .box-area{
    margin-top: 1em;
  }
  .slider-box{
    width:40%
  }
}

@media only screen and (max-width: 768px) {
  .box-area{
    margin-top: 1em;
  }
  .slider-box{
    width:80%;
  }
  .slider-header{
    font-size: 6vw;
  }
}
