.App {
  background-color: white;
}

.Form {
  width: 460px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #ffff;
  border-radius: 2rem;
}

.Form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Form-title {
  font-family: "monospace";
  text-align: left;
  margin-bottom: 1em;
  font-size: 19px;
  color: #1a98d5;
  font-weight: 800;
  margin: 1px;
  padding: 1px;
}

label {
  font-family: "monospace";
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.Form-control {
  width: 320px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.5rem;
  background-color: white;
  margin: 0.5rem;
}

.Form-control::placeholder {
  padding: 15px;
}

.login-btn {
  margin: 5px;
  border-radius: 1rem;
  width: 136px;
  height: 34px;
  background-color: #1a98d5;
  color: #ffff;
  font-weight: 600;
  font-family: "monospace";
  border: none;
}

.login-button-div {
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
}

/*.Rectangle_9_copy_2 {
  border-radius: 11px;
  background-color: rgb(8, 133, 192);
  box-shadow: inset 0px 3px 6px 0px rgba(0, 0, 0, 0.18);
  position: absolute;
  left: 1221px;
  top: 577px;
  width: 136px;
  height: 34px;
  z-index: 51;
}
 */

.Forgot-password {
  position: relative;
  text-align: center;
  font-family: "monospace";
}
.Forgot-password span {
  text-decoration: none;
  color: #1295d6;
  cursor: pointer;
}
.Form-control {
  border: none;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.4);
  outline-width: 1px;
}

.login-forms {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  top: -5%;
}

/**responsive css kodlari*/
@media only screen and (max-width: 924px) {
  .Form {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .Form {
    width: 400px;
  }
}
