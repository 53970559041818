.appointment-card {
  border: 1px solid #0785c7;
  border-radius: 12px;
}

.doctor-name-label {
  font-size: 1.2em;
  font-weight: 700;
}

.doctor-contact-info-label {
  font-size: .9em;
  font-weight: 300;
}

.appointment-list-wrapper {
  height: 90%;
  overflow: auto;
}

.appointment-dialog{
  width: 65vw;
  height: 50vw;
}

.p-button{
  background: #0885bf !important;
  border: 1px solid white !important;
  color: white !important;
}


.p-tag{
  background: #0885bf !important;
}


/**responsive css kodlari*/

@media only screen and (max-width: 1200px) {
  .appointment-dialog{
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .appointment-dialog{
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .appointment-dialog{
    width: 100%;
    height: 100%;
  }
}

.user-actions {
  border-bottom: 1px solid #0785c7;
  padding-bottom: 8px;
}

.p-inputtext, .p-inputnumber, .p-dropdown {
  width: 100%;
}
