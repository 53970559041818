.login-page-section {
  padding: 200px 0 50px;
  position: relative;
}
.login_form {
  display: flex;
  align-content: center;
  justify-content: center;
}

.login-img {
  position: absolute;
  content: "";
  right: 55%;
  bottom: 40px;
  width: 469px;
  height: 310px;
  background-repeat: no-repeat;
  background-size: contain;
}
.login-img1 {
  position: absolute;
  content: "";
  left: 70%;
  top: 100px;
  width: 29%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}

.login-img2 {
  position: absolute;
  content: "";
  right: 75%;
  bottom: 40px;
  width: 469px;
  height: 310px;
  background-repeat: no-repeat;
  background-size: contain;
}
.login-logo {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 1%;
}

@media only screen and (max-width: 924px) {
  .login-img {
    display: none;
  }
  .login-img2 {
    display: none;
  }
  .login-img1 {
    display: none;
  }
}
