.branchsearch-container{
    display: flex;
    background-color: #0885bf;
    flex-direction: column;
    min-height: 40vw;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.branchsearch-slider-box{
    display: flex;
    box-sizing: border-box;
    width:22%;
    min-height: 250px;
    padding: 1em;
    margin: 1%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 7%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.branchsearch-box-area{
    display: flex;
    height: 90%;
    justify-content: center;
    flex-direction: row;
    margin: 3em 10% 1em 10%;
    flex-wrap: wrap;
}

/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
    .branchsearch-box-area{
        margin-top: 1em;
    }
    .branchsearch-slider-box{
        width:40%
    }
}

@media only screen and (max-width: 768px) {
    .branchsearch-box-area{
        margin-top: 1em;
    }
    .branchsearch-slider-box{
        width:60%;
    }
    .slider-header{
        font-size: 6vw;
    }
}
