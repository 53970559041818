input:focus{
    outline: none;
}

.content1-container{
    display: flex;
    background-color: #0885bf;
    min-height: 42vw;
}

/*left side css kodları*/

.left-side{
    width: 20%;
}

/*center side css kodları*/

.center-side{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.input-area{
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-area input{
    padding: 10px 20px;
    border-radius: 2em;
    font-size: 19px;
    z-index: 1;
    width: 50vw;
    border: none;
}

.input-area div{
    position: relative;
}

.input-area button{
    position: absolute;
    width: 50px;
    padding: 0.78em;
    border-radius: 50%;
    right: 0;
    top:50%;
    transform: translate(0, -50%);
    border: 2px solid #fff;
    background: #0885bf;
    cursor: pointer;
    z-index: 1;
}

.input-area button .fas{
    color: #fff;
}

.content-area{
    height: 80%;
    background-image: url("../../assets/img/bg1.png");
    background-size:100% 100%;
    display: flex;
    margin-top: 1em;
    padding: 2em;
}

.content-area .img-area{
    background-image: url("../../assets/img/bg3.png");
    display: flex;
    flex:1;
    background-size:100% 100%;
    background-position: top;
    background-size: cover;
    transform: scale(1.6);
    z-index: 15;
}

.content-area .login-area{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

/*right side css kodları*/

.right-side{
    width: 20%;
    background-image: url("../../assets/img/bg-2.png");
    background-size: 100% 100%;
}

/**responsive css kodlari*/
@media only screen and (max-width: 1210px) {
    .center-side{
        flex: 1;
    }
    .content-area .img-area{
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .content-area{
        padding: 0;
    }
    .center-side{
        flex: 1;
    }
    .content-area .img-area{
        display: none;
    }
    .input-area{
        margin-top: 5%;
    }
    .input-area input{
        width: 80vw;
        font-size: 15px;
    }
    .input-area button{
        width: 40px;
    }
    .left-side{
        display: none;
    }
    .right-side{
        display: none;
    }
}

.rotateZ270 {
    transform: rotateZ(270deg);
}

@keyframes flip-with-scale {
    0% {
        transform: perspective(400px) scaleX(1);
    }

    100% {
        transform: perspective(400px) scaleX(-1);
    }
}

@keyframes flip-with-rotate {
    0% {
        transform: perspective(400px) rotateY(0);
    }

    100% {
        transform: perspective(400px) rotateY(180deg);
    }
}