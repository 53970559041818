.pages-content {
  margin: 50px 0px;
  margin-bottom: 100px;
}
.page-content {
  background: #fff;
  padding: 25px 20px;
}
.page-content h2 {
  font-family: "monospace";
  color: #0785c7;
  text-align: center;
  font-size: 32px;
}

.content-img {
  float: right;
  margin-bottom: 33px;
  margin-left: 32px;
}
.pages-content-header p {
  font-family: "monospace";
  font-size: 18px;
}

.pages-content-header img {
  max-width: 100%;
}
