.payment-container{
    display:flex;
    justify-content: center;
    margin-top: 5rem;
}
.payment-status{
    display:flex;
    flex-direction: column;
    border:solid #0a58ca 2px;
    border-radius:1em ;
    width:30rem;
    justify-content: center;
    padding: 1%;
}
.payment-img{
    align-items: center;
    justify-content: center;
    display:flex;
    justify-content: center;
}

.payment-text{
    display:flex;
    font-family: "monospace";
    text-align: center;
    font-size:25px;
    font-weight: bold;
    color:#0a58ca;
    justify-content: center;
}

.payment-text1{
    font-family: "monospace";
    font-weight: bolder;
    color:#0a58ca;
    text-align: center;
    display:flex;
    justify-content: center;
}
