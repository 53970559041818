.doctor-box-span{
    display: flex;
    flex:1;
    text-align:center;
    margin:1%;
}

.doctor-box-span-text{
    color:white;
    font-family: "monospace";
}

.doctor-box-span-text-detay{
    color:white;
    font-family: "monospace";
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}

.doctor-box-span-text-detay:hover{
    cursor:pointer;
    opacity : 0.9;
}


/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
    #doctor-box-home-page-div{
        width: 30% !important;
    }
}

@media only screen and (max-width: 768px) {
    #doctor-box-home-page-div{
        width: 70% !important;
    }
}
