.p-card-content{
    padding:0em !important;
}

.p-card-body{
    padding:0 !important;
}

.resultText{
    line-height: 35px;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black
}

.p-divider{
    background-color: #0885bf;
}
