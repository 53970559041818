.main-content {
    padding-top: 100px;
    padding-bottom: 100px;
}

.flex-center {
    align-items: center;
}
.accordion-button{
    margin-bottom: 10px;
}
.accordion-body {
    margin-top: 15px;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}
.circle-icon {

    height: 50px;
    width: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a98d5;
    color: #fff;
    margin-left: -20px;
    margin-right: 10px;
    transform: scale(1.2);
}
.accordion-item{
    border: 0px!important;
}
.accordion-button:not(.collapsed){
    border: 0px!important;
    color: #1a98d5;
    background-color: #ffffff;
    box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
}

.faq-title{
    font-family: "Cocogoose Pro Block", serif;
    color: #1a98d5;
    font-weight: bold;
    text-align: center;
}

.question-title{
    font-family: "DIN[T]", serif;
    font-size: 24px;

}