.doctor-voting-card{
    width: 50%;
}

.doctor-voting-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rating-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

.rating-container span label{
    font-size: 1.875rem !important;
    color: #faaf00 !important;
}

.info-div label{
    font-size: 1.2rem;
    padding: 0.5rem;
}

.p-card-title{
    font-size: 1.5em !important;
    text-align: center;
    text-decoration: underline;
    color: black !important;
}

.vote-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

/**responsive css kodlari*/
@media only screen and (max-width: 924px) {
    .doctor-voting-card{
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .doctor-voting-card{
        width: 100%;
    }
}
