.appointment-container{
    display: flex;
    flex-direction: column;
}

.appointment-title{
    height: 10%;
    display: flex;
    justify-content: center;
}

.appointment-title h3{
    font-weight: bold;
    color: #0885bf;
    font-family: "monospace";
}

.appointment-step{
    height: 80%;
}

.angles-area{
    height: 10%;
    display: flex;
    justify-content: space-between;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #0885bf !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #0885bf !important;
}

.flex-div{
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    padding: 2%;
    flex-wrap: wrap;
}
